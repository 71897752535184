import {useQueryClient} from '@tanstack/react-query'
import * as R from 'rambdax'
import {Fragment, useMemo} from 'react'
import * as Final from 'react-final-form'
import {
  Falsy,
  MetaObject,
  Offer,
  UnknownMetaObject,
  api,
  isCollection,
  isExcerpt,
  logger,
  meta,
  semiSplit,
  stableJson,
} from 'tizra'
import * as B from '../block'
import * as S from './styles'
import {HeaderBlockConfig} from './meta'

const log = logger('HeaderBlock')

/**
 * Invalidate cart queries.
 */
const useInvalidateCart = () => {
  const queryClient = useQueryClient()
  return async () => {
    await queryClient.invalidateQueries({
      predicate: ({queryKey}) =>
        Array.isArray(queryKey) && ['cart', 'cartSize'].includes(queryKey[0]),
    })
  }
}

/**
 * Add something to the cart, either Tizra or remote.
 */
const useAddToCart = ({
  metaObj,
  offers,
}: {
  metaObj: MetaObject
  offers: Offer[]
}) => {
  const invalidateCart = useInvalidateCart()

  // addToCartLinkProp needs to look at the controlled object props.
  // Fetch any controlled objects that don't match the metaObj we already have,
  // and make a lookup table.
  const queries = B.useApis(
    Array.from(new Set(offers.map(o => o.controlled.tizraId)))
      .filter(objectId => objectId !== metaObj.tizraId)
      .map(objectId => ['query', {'tizra-id': objectId}]),
  ) as B.UseApiReturnType<'query'>[]
  const lookup = Object.fromEntries<MetaObject | undefined>(
    queries
      .flatMap(q => q.data || [])
      .concat(metaObj)
      .map(o => [o.tizraId, o]),
  )

  return async ({offer, objectId}: {offer: Offer; objectId: string}) => {
    // Check for remote cart.
    const addToCartLinkProp = meta.string(offer, 'AddToCartLinkProp')
    const addToCartLink =
      (addToCartLinkProp && meta.string(lookup[objectId], addToCartLinkProp)) ||
      meta.string(offer, 'AddToCartLink')
    if (addToCartLink) {
      // Invalidating the cart creates a race between following the link, and
      // updating the cart icon in the nav. Ideally we'd disable that query
      // instead of racing, but I haven't figured out a reasonable way to do
      // that yet.
      await invalidateCart()

      // Follow the link to the remote add-to-cart page.
      window.location.href = addToCartLink

      return
    }

    // Add to Tizra cart.
    await api.cartAdd({offerId: offer.tizraId, objectId})

    // Add notification bubble to cart icon.
    await invalidateCart()
  }
}

/**
 * Check if any of the offers (return value from relevant-offers API) is already
 * in the cart. We only check the item id, not the offer id, because the Tizra
 * cart doesn't handle multiple offers for the same item. If we attempt to add
 * a second offer for the same object id (even if they're separate, for
 * example ebook and print book), then the second offer will replace the first.
 * https://github.com/Tizra/evergreen/issues/337
 */
const useOfferIdsInCart = (offers: Array<Offer>): string[] | undefined => {
  const {data: cart} = B.useApi.cart()
  if (cart) {
    const idsInCart = new Set(cart.map(c => c.item.tizraId))
    return offers
      .filter(o => idsInCart.has(o.controlled.tizraId))
      .map(o => o.tizraId)
  }
}

const prefixMatch = (offer: Offer) => {
  const m = meta.string(offer, 'offerName')?.match(/^([^:]+)(:)\s+(\S.*)$/s)
  return m && {lhs: m[1].trim(), sep: m[2], rhs: m[3].trim()}
}

const OfferPrice = ({
  offer,
  labelSeparator = ':',
  offerName = meta.string(offer, 'offerName'),
}: {
  offer: Offer
  labelSeparator?: string
  offerName?: string
}) => (
  <Fragment>
    {offerName}
    {!!offerName && labelSeparator + ' '}
    <B.Currency amount={offer.price} currency={offer.currencyInfo || 'USD'} />
  </Fragment>
)

const OfferBlurb = ({
  offer,
  alignment,
}: {
  offer: Offer
  alignment: HeaderBlockConfig['alignment']
}) => (
  <B.MetaValue
    metaObj={offer}
    prop="buyingBlurb"
    as={B.Text}
    html={true /* if html, don't simplify */}
    style={{textAlign: alignment}}
  />
)

const MultipleOfferLabel = ({
  offers,
  includeBlurb = true,
  metaObj,
}: {
  offers: Offer[]
  includeBlurb?: boolean
  metaObj?: MetaObject
  pageId?: string
}) => {
  // If our offers have a common prefix, for example "Electronic issue: Member
  // price" and "Electronic issue: Non-member price" then we'll only display the
  // prefix once.
  const prefixes = offers.map(prefixMatch)
  const commonPrefix =
    prefixes.length > 1 &&
    prefixes.every(p => p && p.lhs === prefixes[0]!.lhs) &&
    prefixes[0]!.lhs

  // When displaying multiple _items_ for purchase (as opposed to multiple ways
  // to buy one item) then conditionally include linked title to clarify.
  const {controlled} = offers[0]
  const clarifyObj = B.useMetaObj(
    !!metaObj && controlled.tizraId !== metaObj.tizraId && controlled,
  )
  const clarification = clarifyObj && (
    <B.Text italic>
      <B.MetaLink metaObj={clarifyObj} bypass="unlink" />
    </B.Text>
  )

  return (
    <B.Stack spacing="xxs">
      {commonPrefix && (
        <B.Text>
          {commonPrefix}
          {clarification && <br />}
          {clarification}
        </B.Text>
      )}
      <B.Text>
        {offers.map((offer, i) => {
          const offerName =
            commonPrefix ? prefixes[i]!.rhs : meta.string(offer, 'offerName')
          const price = <OfferPrice offer={offer} offerName={offerName} />
          return (
            <Fragment key={i}>
              {i === 0 ?
                <strong>{price}</strong>
              : <span>
                  <br />
                  {price}
                </span>
              }
            </Fragment>
          )
        })}
      </B.Text>
      {!commonPrefix && clarification}
      {includeBlurb && <OfferBlurb offer={offers[0]} alignment="left" />}
    </B.Stack>
  )
}

/**
 * PickAnItem means two or more items available, each with a single term sheet
 * (like TakeItOrLeaveIt for a single item). Present choices with radio buttons.
 * If there are multiple offers within a radio button, then automatically choose
 * the least expensive, since they have the same term sheets.
 */
const PickAnItem = ({
  itemOffers,
  metaObj,
  pageId,
}: {
  itemOffers: Offer[][]
  metaObj: MetaObject
  pageId: string | undefined
}) => {
  // Caller has already moved the preferred offer to the first position,
  // otherwise maintaining the sort.
  const bestOffers = itemOffers.map(offers => offers[0])
  const alreadyInCart = useOfferIdsInCart(bestOffers)
  const addToCart = useAddToCart({metaObj, offers: bestOffers})

  const blurbs = bestOffers
    .flat()
    .map(offer => meta.string(offer, 'buyingBlurb')?.trim())

  const haveBlurbs = blurbs.some(Boolean)

  // If blurbs are equal, we can show just one, below the radio buttons. If
  // blurbs aren't equal, then we need to show them associated with the
  // individual choices.
  const blurbsAreEqual = blurbs.every(b => b === blurbs[0])

  // Final Form will call onSubmit with the object of values, which consists
  // solely of objectId.
  const onSubmit = async ({objectId}: any) => {
    // Sanity checks, even though these messages won't be rendered.
    if (!objectId) {
      log.warn('Attempted to add undefined itemId to cart')
      return {itemId: 'Missing value'}
    }
    const offer = bestOffers.find(
      offer => offer.controlled.tizraId === objectId,
    )
    if (!offer) {
      log.warn('Could not resolve itemId to offerId', objectId)
      return {itemId: 'Invalid'}
    }

    await addToCart({offer, objectId})

    // Return undefined so Final Form knows that the
    // form was submitted successfully.
  }

  // Select the first radio option by default.
  const initialValues = {objectId: bestOffers[0].controlled.tizraId}

  return (
    <Final.Form initialValues={initialValues} onSubmit={onSubmit}>
      {({handleSubmit, submitting, submitSucceeded}) => (
        <form onSubmit={handleSubmit}>
          <B.Stack spacing="xxl">
            <B.Stack spacing="lg">
              <Final.Field name="objectId">
                {({input}) => (
                  <B.Radios
                    options={itemOffers.map(offers => ({
                      value: offers[0].controlled.tizraId,
                      label: (
                        <MultipleOfferLabel
                          offers={offers}
                          includeBlurb={haveBlurbs && !blurbsAreEqual}
                          metaObj={metaObj}
                          pageId={pageId}
                        />
                      ),
                    }))}
                    spacing="lg"
                    {...input}
                  />
                )}
              </Final.Field>
              {haveBlurbs && blurbsAreEqual && (
                <OfferBlurb
                  offer={bestOffers[0]}
                  alignment="left" // align with radios
                />
              )}
            </B.Stack>
            <B.Button
              fluid
              type="submit"
              disabled={
                alreadyInCart === undefined ||
                // TODO proper check
                !!alreadyInCart.length ||
                submitting
              }
            >
              {(
                // TODO proper check
                !!alreadyInCart?.length ||
                (submitSucceeded && alreadyInCart === undefined)
              ) ?
                'Added to your cart!'
              : 'Add to cart'}
            </B.Button>
          </B.Stack>
        </form>
      )}
    </Final.Form>
  )
}

/**
 * PickAnOffer means two or more groups of offers with different term
 * sheets. Present choices with radio buttons. If there are multiple offers within
 * a radio button, then automatically choose the least expensive, since they have
 * the same term sheets.
 */
const PickAnOffer = ({
  groupedOffers,
  metaObj,
}: {
  groupedOffers: Offer[][]
  metaObj: MetaObject
}) => {
  const groupLeaders = groupedOffers.map(offers => offers[0])
  const alreadyInCart = useOfferIdsInCart(groupLeaders)
  const addToCart = useAddToCart({metaObj, offers: groupLeaders})

  const blurbs = groupedOffers
    .flat()
    .map(offer => meta.string(offer, 'buyingBlurb')?.trim())

  const haveBlurbs = blurbs.some(Boolean)

  // If blurbs are equal, we can show just one, below the radio buttons. If
  // blurbs aren't equal, then we need to show them associated with the
  // individual choices.
  const blurbsAreEqual = blurbs.every(b => b === blurbs[0])

  // Final Form will call onSubmit with the object of values, which consists
  // solely of offerId.
  const onSubmit = async ({offerId}: any) => {
    // Sanity checks, even though these messages won't be rendered.
    if (!offerId) {
      log.warn('Attempted to add undefined offerId to cart')
      return {offerId: 'Missing value'}
    }
    const offer = groupLeaders.find(offer => offer?.tizraId === offerId)
    const objectId = offer?.controlled.tizraId
    if (!objectId) {
      log.warn('Could not resolve offerId to objectId', offerId)
      return {offerId: 'Invalid'}
    }

    await addToCart({offer, objectId})

    // Return undefined so Final Form knows that the
    // form was submitted successfully.
  }

  // Select the already-added offer, or the first radio option by default.
  const initialValues = {
    offerId: alreadyInCart?.[0] || groupLeaders[0].tizraId,
  }

  return (
    <Final.Form initialValues={initialValues} onSubmit={onSubmit}>
      {({handleSubmit, submitting, submitSucceeded, values}) => (
        <form onSubmit={handleSubmit}>
          <B.Stack spacing="xxl">
            <B.Stack spacing="lg">
              <Final.Field name="offerId">
                {({input}) => (
                  <B.Radios
                    options={groupedOffers.map(offers => ({
                      value: offers[0].tizraId,
                      label: (
                        <MultipleOfferLabel
                          offers={offers}
                          includeBlurb={haveBlurbs && !blurbsAreEqual}
                        />
                      ),
                    }))}
                    spacing="lg"
                    {...input}
                  />
                )}
              </Final.Field>
              {haveBlurbs && blurbsAreEqual && (
                <OfferBlurb
                  offer={groupLeaders[0]}
                  alignment="left" // align with radios
                />
              )}
            </B.Stack>
            <B.Button
              fluid
              type="submit"
              disabled={
                alreadyInCart === undefined ||
                alreadyInCart?.includes(values.offerId) ||
                submitting
              }
            >
              {(
                alreadyInCart?.includes(values.offerId) ||
                (submitSucceeded && alreadyInCart === undefined)
              ) ?
                'Added to your cart!'
              : 'Add to cart'}
            </B.Button>
          </B.Stack>
        </form>
      )}
    </Final.Form>
  )
}

/**
 * TakeItOrLeaveIt means one or more offers with the same term sheet. Choose
 * and highlight the least expensive applicable offer; the others are shown
 * de-emphasized for comparison.
 */
const TakeItOrLeaveIt = ({
  alignment,
  metaObj,
  offers,
}: Pick<HeaderBlockConfig, 'alignment'> & {
  metaObj: MetaObject
  offers: Offer[]
}) => {
  const alreadyInCart = useOfferIdsInCart(offers)
  const addToCart = useAddToCart({metaObj, offers})

  // Caller has already moved the preferred offer to the first position,
  // otherwise maintaining the sort.
  const [bestOffer] = offers

  const onSubmit = async () => {
    await addToCart({
      offer: bestOffer,
      objectId: bestOffer.controlled.tizraId,
    })
    // Return undefined so Final Form knows that the
    // form was submitted successfully.
  }

  return (
    <Final.Form onSubmit={onSubmit}>
      {({handleSubmit, submitting, submitSucceeded}) => (
        <form onSubmit={handleSubmit}>
          <B.Stack spacing="xxl">
            <B.Stack spacing="lg" wrapChildren={false}>
              {offers.map((offer, i) => (
                <B.Text bold={i === 0} style={{textAlign: alignment}} key={i}>
                  <OfferPrice offer={offer} />
                </B.Text>
              ))}
              <OfferBlurb offer={bestOffer} alignment={alignment} />
            </B.Stack>
            <div>
              <B.Button
                fluid
                type="submit"
                disabled={
                  alreadyInCart === undefined ||
                  alreadyInCart?.includes(bestOffer.tizraId) ||
                  submitting
                }
              >
                {(
                  alreadyInCart?.includes(bestOffer.tizraId) ||
                  (submitSucceeded && alreadyInCart === undefined)
                ) ?
                  'Added to your cart!'
                : 'Add to cart'}
              </B.Button>
            </div>
          </B.Stack>
        </form>
      )}
    </Final.Form>
  )
}

type GainProps = Pick<HeaderBlockConfig, 'alignment'> & {
  asModal?: boolean
  metaObj: MetaObject | undefined
  pageId: string | undefined // access denied
  offers: Offer[]
}

const cheapest = (offers: Offer[]) =>
  offers.reduce((best, offer) => (offer.price < best.price ? offer : best))

const cheapestFirst = (offers: Offer[]) => {
  const first = cheapest(offers)
  return first ? [first, ...offers.filter(offer => offer !== first)] : offers
}

/**
 * Group offers by controlled id.
 */
const groupByControlledId = (offers: Offer[]): Offer[][] =>
  R.piped(
    offers,
    R.groupBy(o => o.controlled.tizraId),
    R.values,
  )

/**
 * Group offers by equivalent term sheets, and make the cheapest the first one
 * in each list.
 */
const groupByTermSheet = (offers: Offer[]): Offer[][] =>
  R.piped(
    offers,
    R.groupBy(offer => stableJson(offer.termSheet)),
    R.values,
    R.map(cheapestFirst),
  )

const isOneEffectiveOfferPerId = (groupedOffers: Offer[][][]): boolean =>
  groupedOffers.every(group => group.length === 1)

const isOneIdPerMetaType = (groupedOffers: Offer[][][]): boolean =>
  R.piped(
    groupedOffers,
    R.map(groupsPerId => groupsPerId[0]!),
    R.countBy(offers => offers[0]!.controlled.metaType),
    R.values,
    R.all(R.equals(1)),
  )

const canDisplay = (groupedOffers: Offer[][][]): boolean =>
  // We only have offers on one item, so we can display them directly.
  groupedOffers.length === 1 ||
  // We have offers on multiple items, but they can be displayed as
  // a one-dimensional list.
  (isOneEffectiveOfferPerId(groupedOffers) && isOneIdPerMetaType(groupedOffers))

export const Gain = ({
  alignment,
  asModal,
  metaObj,
  offers,
  pageId,
}: GainProps) => {
  const modal = B.Modal.useStore()

  // Four places we might be:
  //
  // 1. book toc
  //    - metaObj is book
  //    - offers for book/collection(s)
  //    - collection offers should additionally link
  //
  // 2. excerpt toc
  //    - metaObj is excerpt
  //    - offers for excerpt/book/collection(s)
  //    - book/collection offers should additionally link
  //
  // 3. collection toc
  //    - metaObj is collection
  //    - offers for collection(s) (incl. super collections)
  //    - other collections should additionally link
  //
  // 4. access-denied
  //    - metaObj is book
  //    - offers for excerpt(s)/book/collection(s)
  //    - collections should additionally link
  //
  // Summary form of deciding whether to indirect:
  //
  //    - We have more than one effective offer per id.
  //
  // Summary form of deciding whether to additionally link:
  //
  //    - book or collection that isn't metaObj
  //
  // We display offers in one of two ways:
  //
  // 1. Multiple items that can be bought one way. There is no indirection
  //    because the available options are all presented.
  //
  // 2. One or multiple ways to buy one item, with indirection for buying other
  //    items as needed.
  //
  // N.B. There are two kinds of offers on collections: distributive and
  // non-distributive. In the former case, the offer applies to each individual
  // item in the collection; when we receive it from the API, it appears to be an
  // offer directly on the item. In the latter case, the offer applies to the
  // entire collection as a whole.

  const [directOffers, indirectOffers] = useMemo<
    [Offer[][][], Offer[][][]]
  >(() => {
    if (!metaObj || !offers.length) {
      return [[], []]
    }

    // This gets a little bit deep, but it's:
    // - first tier: group by id
    // - second tier: group by termsheet
    const groupedOffers: Offer[][][] = R.piped(
      offers,
      groupByControlledId,
      R.map(groupByTermSheet),
    )

    if (groupedOffers.length === 0) {
      // This shouldn't happen, because we checked offers.length above.
      log.error('constraint failed: groupedOffers.length is zero')
      return [[], []]
    }

    // Separate direct vs. indirect
    //
    // The concept of current obj is complicated for access-denied with
    // overlapping excerpts, where there can be multiple "current" objs including
    // the containing book.
    //
    // Define a list of predicates that partition relevant offers into
    // 1. things we would like to display directly,
    // 2. things that will be indirectly linked.
    //
    // Find the first partitioning that returns offers and passes the
    // canDisplay() test.

    const onAccessDenied = !!pageId
    const onExcerptToc = isExcerpt(metaObj)
    //const onCollectionToc = isCollectionMetaType(metaObj.metaType)
    //const onBookToc = !onAccessDenied && !onExcerptToc && !onCollectionToc
    const isBook = (metaObj: UnknownMetaObject) =>
      !isCollection(metaObj) && !isExcerpt(metaObj)

    const partitioners: Array<
      [string, Falsy | ((controlled: Offer['controlled']) => boolean)]
    > = [
      ['everything', () => true],

      [
        'all excerpts and containing book',
        controlled => !isCollection(controlled),
      ],

      [
        'this excerpt and containing book',
        onExcerptToc &&
          (controlled =>
            isExcerpt(controlled) ?
              controlled.tizraId === metaObj.tizraId
            : isBook(controlled)),
      ],

      [
        'all excerpts',
        (onAccessDenied || onExcerptToc) &&
          (controlled => isExcerpt(controlled)),
      ],

      [
        'this excerpt',
        onExcerptToc && (controlled => controlled.tizraId === metaObj.tizraId),
      ],

      ['book and collections', controlled => !isExcerpt(controlled)],

      [
        'this book/collection',
        isExcerpt(metaObj) ?
          controlled => controlled.tizraId === metaObj.parent.tizraId
        : controlled => controlled.tizraId === metaObj.tizraId,
      ],
    ]

    for (const [desc, pred] of partitioners) {
      const _plog = log.logger(`partitioner: ${desc}`),
        plog = _plog.log.bind(_plog)
      if (!pred) {
        plog('n/a')
        continue
      }
      const [directOffers, indirectOffers] = R.partition(
        os => pred(os[0][0].controlled),
        groupedOffers,
      )
      if (!directOffers.length) {
        plog('no direct offers')
        continue
      }
      if (!canDisplay(directOffers)) {
        plog('too complex')
        continue
      }
      plog('bingo')
      return [directOffers, indirectOffers]
    }

    return [[], groupedOffers]
  }, [metaObj, offers, pageId])

  if (!metaObj || !offers.length) {
    return null
  }

  const indirectIds = indirectOffers.map(os => os[0][0].controlled.tizraId)

  const innerAlignment = asModal ? 'left' : alignment

  log.debug?.({
    metaObj,
    offers,
    directOffers,
    indirectOffers,
    indirectIds,
    innerAlignment,
  })

  const offersBox = (
    <div style={{display: 'flex', justifyContent: B.flexAlign(innerAlignment)}}>
      <S.OffersStack
        asModal={!!asModal}
        spacing="xxl"
        style={{alignItems: B.flexAlign(innerAlignment)}}
      >
        {!!indirectOffers.length && (
          <B.Text style={{textAlign: innerAlignment}}>
            This item can {directOffers.length ? 'also' : ''} be purchased as
            part of:
            {indirectIds.map((tizraId, i) => (
              <Fragment key={tizraId}>
                {i === 0 ?
                  ' '
                : i === indirectIds.length - 1 ?
                  ', and '
                : ', '}
                <B.MetaLink tizraId={tizraId} />
              </Fragment>
            ))}
          </B.Text>
        )}
        {!directOffers.length ?
          null
        : directOffers.length === 1 ?
          directOffers[0].length === 1 ?
            <TakeItOrLeaveIt
              alignment={innerAlignment}
              metaObj={metaObj}
              offers={directOffers[0][0]}
            />
          : <PickAnOffer groupedOffers={directOffers[0]} metaObj={metaObj} />
        : <PickAnItem
            itemOffers={directOffers.map(groupedOffers => groupedOffers[0])}
            metaObj={metaObj}
            pageId={pageId}
          />
        }
      </S.OffersStack>
    </div>
  )

  return asModal ?
      <div style={{display: 'flex', justifyContent: B.flexAlign(alignment)}}>
        <B.Link onClick={modal.show} {...B.tid('purchasing-options')}>
          See purchasing options
        </B.Link>
        <B.Modal store={modal}>
          <B.Modal.Content>{offersBox}</B.Modal.Content>
        </B.Modal>
      </div>
    : offersBox
}

Gain.displayName = 'HeaderBlock.Gain'

type UseOffersProps = Pick<HeaderBlockConfig, 'callsToAction'> & {
  metaObj: MetaObject | undefined
  pageId: string | undefined
}

type UseOffersReturn = undefined | Offer[]

export const useOffers = ({
  callsToAction: {
    gain: {
      requiredTags,
      //sortBy
    },
  },
  metaObj,
  pageId,
}: UseOffersProps): UseOffersReturn => {
  // TODO sort
  const tizraId = pageId || metaObj?.tizraId
  return B.useApi.relevantOffers(
    tizraId && {tizraId, requiredTags: semiSplit(requiredTags)},
  ).data as undefined | Offer[]
}
