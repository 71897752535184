import * as R from 'rambdax'
import {useNavigate} from 'react-router-dom'
import {Checkout, InfoItem, logger} from 'tizra'
import {plural} from 'tizra/meta'
import * as B from '../block'
import {CartItem} from './CartItem'
import * as S from './styles'

const log = logger('CartBlock/OrderSummary')

const useOrderSummary = ({checkout}: {checkout?: Checkout}) => {
  // Classic will pass empty infoItems in dummy checkout response. We need to
  // fetch cart here to calculate subtotal on our own.
  //
  // Additionally, if the user isn't signed in, or if the checkout picker is
  // shown, then there won't be a checkout response yet. In that case, we also
  // fetch the cart here so we can show the order summary.
  //
  // There's a race condition here where we'll start fetching the cart response
  // while checkout is on the way. It's not a big deal because we almost
  // certainly have the cart response already in react-query cache from being on
  // the cart screen.
  const {data: _cart} = B.useApi.cart(
    !checkout || checkout?.name === B.FULFILLER_BUTTONS_CHECKOUT_NAME,
  )
  const cart = _cart || checkout?.cart

  let infoItems = checkout?.infoItems
  if (!infoItems?.length && cart) {
    const currency =
      (cart[0] &&
        log.assert(
          cart[0].offer.currencyInfo,
          'missing currency-info in cart API response',
        )) ||
      'USD'
    const value = cart.reduce((sum, {offer: {price}}) => sum + price, 0)
    infoItems = [
      {
        type: 'sub-total',
        message: 'Subtotal',
        quantity: {
          value,
          format: {style: 'currency', currency},
        },
      },
    ]
  }

  return {cart, infoItems}
}

export const MobileCartSummary = ({checkout}: {checkout?: Checkout}) => {
  const {cart, infoItems} = useOrderSummary({checkout})

  const subTotal = infoItems?.find(item => item.type === 'sub-total')

  return (
    <B.Text variant="textMd">
      {cart && (
        <div>
          {cart.length} {plural('item(s)', cart.length)}
          {subTotal?.quantity && (
            <>
              {' '}
              for{' '}
              <B.Currency
                amount={subTotal.quantity.value}
                currency={subTotal.quantity.format?.currency || 'USD'}
              />
            </>
          )}
        </div>
      )}
    </B.Text>
  )
}

const InfoItemSummary = ({
  message,
  quantity,
  type,
  infoItems,
  i,
}: InfoItem & {infoItems: InfoItem[]; i: number}) => {
  // HACK: simplistic formatting for UMPH delivery
  const variant = type === 'total' || infoItems.length === 1 ? 'h5' : undefined
  return (
    <B.LeftRight key={i}>
      <B.Text variant={variant} as="div">
        {message}
      </B.Text>
      {quantity && (
        <B.Text variant={variant} as="div">
          <B.Currency
            amount={quantity.value}
            currency={quantity.format?.currency || 'USD'}
          />
        </B.Text>
      )}
    </B.LeftRight>
  )
}

export const MobileOrderSummary = ({checkout}: {checkout?: Checkout}) => {
  const {infoItems} = useOrderSummary({checkout})

  if (!infoItems) return null

  return (
    <B.Stack>
      {infoItems.map((infoItem, i) => (
        <InfoItemSummary {...infoItem} infoItems={infoItems} i={i} key={i} />
      ))}
    </B.Stack>
  )
}

export const DesktopOrderSummary = ({checkout}: {checkout?: Checkout}) => {
  const {cart, infoItems} = useOrderSummary({checkout})
  const navigate = useNavigate()

  if (!infoItems) return null

  const cartLength = cart?.length || 0
  const cartLimit = Infinity
  const cartExtra = Math.max(0, cartLength - cartLimit)

  return (
    <B.Stack spacing="xxl">
      <S.BorderedStack spacing="xxl">
        <B.LeftRight>
          <B.Text variant="h5">Order Summary</B.Text>
          <B.Text variant="textMd">
            <B.Link onClick={() => navigate('#cart')}>Edit cart</B.Link>
          </B.Text>
        </B.LeftRight>
        <B.Stack spacing="xs" divided>
          <B.Text>
            {cart?.length} item{cart?.length !== 1 && 's'}
          </B.Text>
          <B.Stack>
            {cart &&
              R.take(cartLimit, cart).map((x: any, i: number) => (
                <CartItem {...x} variant="checkout" key={i} />
              ))}
          </B.Stack>
          {!!cartExtra && (
            <B.Text>
              and {cartExtra} more {plural('item(s)', cartExtra)}
            </B.Text>
          )}
        </B.Stack>
      </S.BorderedStack>
      <B.Stack capped>
        {infoItems.map((infoItem, i) => (
          <InfoItemSummary {...infoItem} infoItems={infoItems} i={i} key={i} />
        ))}
      </B.Stack>
    </B.Stack>
  )
}
