import {logger} from 'tizra'
import * as B from '../block'
import {CheckoutWizard} from './CheckoutWizard'
import {CartBlockConfig} from './meta'
import {
  MobileCartSummary,
  MobileOrderSummary,
  DesktopOrderSummary,
} from './OrderSummary'
import {useBookshelfConfig} from '../BookshelfBlock'
import {useEffect} from 'react'
import S from './styles.module.css'
import {useNavigate} from 'react-router-dom'

const log = logger('CartBlock/Checkout')

interface CheckoutProps {
  config: CartBlockConfig
}

const CheckoutModal = (props: NonNullable<B.UseCheckoutReturn['dialog']>) => {
  const modal = B.Modal.useStore({defaultOpen: true})

  // If action changes, then re-show the modal. This allows the modal to be
  // reused after it's closed.
  useEffect(() => modal.show(), [modal, props.action])

  return (
    <B.Modal store={modal} onClose={props.action} hideOnInteractOutside={false}>
      <B.Modal.Title>{props.title}</B.Modal.Title>
      <B.Modal.Content>{props.message}</B.Modal.Content>
      <B.Modal.Footer>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <B.Button onClick={modal.hide}>{props.button}</B.Button>
        </div>
      </B.Modal.Footer>
    </B.Modal>
  )
}

export const Checkout = ({config}: CheckoutProps) => {
  const navigate = useNavigate()
  const userData = B.useUserData()
  const bookshelfConfig = useBookshelfConfig()
  const isMobile = B.useMobile()

  const {
    checkouts,
    defaultCheckout,
    checkoutName,
    setCheckoutName,
    checkout,
    submit,
    loading,
    dialog,
  } = B.useCheckout({bookshelfConfig})

  const wizard =
    userData === null || !checkouts ?
      null
    : <CheckoutWizard
        // Re-render from scratch whenever checkoutName changes.
        key={checkoutName || '_pick'}
        cartSummary={isMobile && <MobileCartSummary checkout={checkout} />}
        orderSummary={isMobile && <MobileOrderSummary checkout={checkout} />}
        needContainer={isMobile}
        {...{
          config,
          userData,
          checkouts,
          defaultCheckout,
          checkoutName,
          setCheckoutName,
          checkout,
          submit,
          loading,
        }}
      />

  const {logoUrl, logoLink} = B.useGlobalBlockConfig('theme')

  // Prevent body from scrolling underneath our mobile div overlay.
  B.useIsomorphicLayoutEffect(() => {
    if (isMobile) {
      document.body.classList.add(S.noscroll)
      return () => {
        document.body.classList.remove(S.noscroll)
      }
    }
  }, [isMobile])

  return (
    <>
      {isMobile ?
        <div className={S.overlay}>
          <B.MinimalNav
            logoUrl={logoUrl}
            logoLink={logoLink}
            onClose={() => navigate('#cart')}
          />
          <B.Section
            bleed
            variant="abutting"
            style={{
              // Make sure that the button isn't unreachable somehow.
              paddingBottom: '30vh',
            }}
          >
            <B.Stack divided spacing="lg">
              {wizard}
            </B.Stack>
          </B.Section>
        </div>
      : <B.Section>
          <B.Grid cols={2} colGap="xxxl">
            {wizard || <div />}
            <DesktopOrderSummary checkout={checkout} />
          </B.Grid>
        </B.Section>
      }
      {dialog && <CheckoutModal {...dialog} />}
      <B.Spinner loading={loading} fullscreen />
    </>
  )
}
