import {space} from 'quickstart/theme'
import * as R from 'rambdax'
import {ReactNode, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {CheckoutStep as TCheckoutStep, logger, truthy} from 'tizra'
import {SetRequired} from 'type-fest'
import * as B from '../block'
import {CheckoutStep} from './CheckoutStep'
import {FulfillerButtons} from './FulfillerButtons'
import {PickCheckout} from './PickCheckout'
import {SignIn} from './SignIn'
import {CartBlockConfig} from './meta'
import * as S from './styles'
import {StepComponent} from './types'

const log = logger('CartBlock/CheckoutWizard')

const CIRCLED_NUMBERS = [
  '⓪',
  '①',
  '②',
  '③',
  '④',
  '⑤',
  '⑥',
  '⑦',
  '⑧',
  '⑨',
] as const

const StepHeading = ({
  number,
  children,
}: {
  children: ReactNode
  number: number
}) => {
  const checkoutApiEnabled = B.useHack('checkoutApi')
  const checkoutNumbersEnabled = B.useHack('checkoutNumbers')
  return checkoutNumbersEnabled || !checkoutApiEnabled ?
      <S.NumberedHeading>
        <div>{CIRCLED_NUMBERS[number]}</div>
        <div>{children}</div>
      </S.NumberedHeading>
    : <S.Heading>{children}</S.Heading>
}

interface CheckoutWizardProps
  extends SetRequired<B.UseCheckoutReturn, 'checkouts'> {
  config: CartBlockConfig
  userData: Exclude<ReturnType<typeof B.useUserData>, null>
  cartSummary?: ReactNode
  orderSummary?: ReactNode
  needContainer: boolean
}

const DummyStep: StepComponent = ({children}) => <div>{children}</div>

interface DisplayStep {
  title: string
  Component: StepComponent
  step: TCheckoutStep
  children?: ReactNode
  editable?: boolean
  edit?: () => void
  style?: any
}

export const CheckoutWizard = ({
  config,
  userData,
  checkouts,
  checkoutName,
  checkout,
  loading,
  cartSummary,
  orderSummary,
  needContainer,
  submit: _submit,
  ...props
}: CheckoutWizardProps) => {
  const navigate = useNavigate()

  let displaySteps = useMemo<DisplayStep[]>(() => {
    const checkoutSteps =
      checkout?.steps
        .filter(step => !step.hidden)
        .map(step => ({
          title: step.displayName ?? step.name,
          Component:
            step.name === B.FULFILLER_BUTTONS_STEP_NAME ?
              FulfillerButtons
            : CheckoutStep,
          step,
        })) || []

    // TODO: Investigate TS enums to avoid these.
    const COMPLETE = 'complete' as const
    const INCOMPLETE = 'incomplete' as const

    const displaySteps: DisplayStep[] = [
      cartSummary && {
        title: 'Cart summary',
        Component: DummyStep,
        step: {name: '_cartSummary', status: COMPLETE},
        children: cartSummary,
        edit: () => navigate('#cart'),
        style: {
          backgroundColor: 'var(--eg-theme-colors-backgroundColorLight, white)',
        },
      },
      {
        title: userData ? 'Account info' : 'Sign in',
        Component: SignIn,
        step: {name: '_signin', status: userData ? COMPLETE : INCOMPLETE},
      },
      !!userData &&
        checkouts.length !== 1 && {
          title: 'Checkout method',
          Component: PickCheckout,
          step: {name: '_pick', status: checkout ? COMPLETE : INCOMPLETE},
        },
      ...R.dropLast(1, checkoutSteps),
      checkout?.checkoutStatus === COMPLETE &&
        orderSummary && {
          title: 'Checkout summary',
          Component: DummyStep,
          step: {name: '_orderSummary', status: COMPLETE},
          children: orderSummary,
          editable: false,
          style: {
            backgroundColor:
              'var(--eg-theme-colors-backgroundColorLight, white)',
          },
        },
      R.last(checkoutSteps),
    ].filter(truthy)

    return displaySteps
  }, [cartSummary, checkout, checkouts, navigate, orderSummary, userData])

  // Normally, the active step is the first one that isn't complete. But if the
  // user clicked Edit on a prior step, then that becomes the active step until
  // completed or canceled.
  let currentIndex = displaySteps.findIndex(
    ({step}) => step.status !== 'complete' || step.final,
  )

  // currentIndex and therefore activeIndex can be -1, especially during initial
  // loading. Don't attempt to correct this until finished loading, otherwise it
  // confusingly shows the sign in form temporarily.
  if (currentIndex < 0 && !loading) {
    log.error('Unexpected currentIndex:', currentIndex)
    currentIndex = 0
  }

  let [editIndex, setEditIndex] = useState<number | null>(null)
  if (editIndex !== null && editIndex > currentIndex) {
    log.error('Invalid combination:', {currentIndex, editIndex})
    editIndex = null
  }
  const activeIndex = editIndex ?? currentIndex
  const editing = editIndex !== null

  // Trim trailing steps so we're not showing headings with nothing else.
  displaySteps = R.dropLastWhile(
    ({step}) =>
      step !== displaySteps[currentIndex]?.step &&
      (step.final || step.status !== 'complete'),
    displaySteps,
  )

  const submit: typeof _submit = async (...args) => {
    const data = await _submit(...args)
    // If there's no data then reset manually-set index so we can proceed.
    if (!data) setEditIndex(null)
    return data
  }

  const MaybeContainer = needContainer ? B.Container : 'div'

  return (
    <B.Stack divided spacing="0" wrapChildren={false}>
      {displaySteps.map(
        (
          {title, Component, step, edit, editable = true, children, style},
          stepIndex,
        ) => {
          const active = activeIndex === stepIndex
          const canEdit = editable && !editing && activeIndex > stepIndex
          edit ||= () => setEditIndex(stepIndex)
          return (
            <B.Stack.Item
              style={{
                // If we use spacing="lg" on the Stack itself, then the first/last
                // items won't get top/bottom padding that we need, respectively.
                paddingBlock: space.lg,
                ...style,
              }}
              key={stepIndex}
            >
              <MaybeContainer>
                <B.Stack>
                  <B.LeftRight>
                    <StepHeading number={stepIndex + 1}>{title}</StepHeading>
                    <B.Text variant="textMd">
                      {canEdit ?
                        <B.Link onClick={edit}>Edit</B.Link>
                      : <span style={{visibility: 'hidden'}}>Edit</span>}
                    </B.Text>
                  </B.LeftRight>
                  <Component
                    // Wait to display form until done loading. This avoids
                    active={active && !loading}
                    userData={userData}
                    config={config}
                    setEditIndex={setEditIndex}
                    myIndex={stepIndex}
                    currentIndex={activeIndex}
                    checkouts={checkouts}
                    checkoutName={checkoutName}
                    checkout={checkout}
                    editing={editing}
                    step={step}
                    submit={submit}
                    children={children}
                    {...props}
                  />
                </B.Stack>
              </MaybeContainer>
            </B.Stack.Item>
          )
        },
      )}
    </B.Stack>
  )
}
