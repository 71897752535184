import {Shape} from 'quickstart/components/layout/Shape'
import styled, {th} from 'quickstart/styled-components/system'
import {falsish} from 'quickstart/types'
import {ComponentProps} from 'react'
import {IconType} from 'react-icons'
import {
  FiArrowDown,
  FiArrowLeft,
  FiArrowRight,
  FiArrowUp,
  FiChevronDown,
  FiHome,
  FiInfo,
  FiMenu,
  FiSearch,
  FiShoppingBag,
  FiUser,
  FiX,
} from 'react-icons/fi'
import {logger} from 'tizra'
import * as S from './styles'

const log = logger('Icon')

const iconConfigs = {
  arrowDown: {Fi: FiArrowDown, title: 'Arrow Down'},
  arrowLeft: {Fi: FiArrowLeft, title: 'Arrow Left'},
  arrowRight: {Fi: FiArrowRight, title: 'Arrow Right'},
  arrowUp: {Fi: FiArrowUp, title: 'Arrow Up'},
  burger: {Fi: FiMenu, title: 'Menu'},
  cart: {Fi: FiShoppingBag, title: 'Shopping Cart'},
  chevronDown: {Fi: FiChevronDown, title: 'Chevron Down'},
  close: {Fi: FiX, title: 'Close'},
  home: {Fi: FiHome, title: 'Home'},
  info: {Fi: FiInfo, title: 'Info'},
  search: {Fi: FiSearch, title: 'Search'},
  user: {Fi: FiUser, title: 'User'},
} as const

type Names = keyof typeof iconConfigs
type Configs = (typeof iconConfigs)[Names]

export const icons = Object.fromEntries(
  Object.entries(iconConfigs).map(
    ([name, {Fa, Fi, ...rest}]: [string, Configs & {Fa?: never}]) => [
      name,
      {
        fill: !!Fa, // Awesome wants, Feather doesn't
        stroke: true,
        InnerIcon: Fa || Fi,
        ...rest,
      },
    ],
  ),
) as {
  [K in Names]: {
    title: string
    stroke: boolean
    fill: boolean
    InnerIcon: Configs['Fi']
  }
}

interface IconPropsBase {
  icon: keyof typeof icons
  color?: string
  notify?: boolean
  size?: string
}

type IconProps = Omit<ComponentProps<IconType>, keyof IconPropsBase> &
  IconPropsBase

const NotificationBubble = styled(Shape).attrs({shape: 'circle'})`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50%;
  height: 50%;
  margin-top: -20%;
  margin-right: -20%;
  background-color: ${th('colors.notificationBubble')};
  border: 1px solid ${th('colors.backgroundColorWhite')};
`

export const Icon = ({
  icon,
  color = 'currentColor',
  notify = false,
  size = '1em',
  style,
  ...props
}: IconProps) => {
  const {InnerIcon, stroke, fill, title} = icons[icon] || {}

  if (!InnerIcon) {
    log.error('unknown icon: %s', icon)
    return null
  }

  return (
    <S.Wrapper style={{height: size}} data-icon-wrapper>
      <S.Icon
        as={InnerIcon}
        title={title}
        {...props}
        style={{
          // N.B. fill:false seems to render as style="fill:" on server
          // and style="" on client, resulting in a hydration mismatch. Avoid
          // this by checking falsish.
          stroke:
            stroke === true ? color
            : falsish(stroke) ? undefined
            : stroke,
          strokeOpacity: stroke === false ? '0' : undefined,
          fill:
            fill === true ? color
            : falsish(fill) ? undefined
            : fill,
          fillOpacity: fill === false ? '0' : undefined,
          ...style,
        }}
      />
      {notify && <NotificationBubble />}
    </S.Wrapper>
  )
}
